<template>
  <div v-if="currentTag">
    <LoadingPage :visible="pageLoading"></LoadingPage>
    <v-container
      fluid
      class="px-0 py-0 mt-0 mb-4"
      style="display: flex; justify-content: center"
    >
      <div style="max-width: 1700px; width: 100%" v-if="!pageLoading">
        <div style="" class="mr-2">
          <vxe-radio-group v-model="currTab" size="mini">
            <vxe-radio-button :label="0" content="日常推荐"></vxe-radio-button>
            <vxe-radio-button :label="1" content="月度推荐"></vxe-radio-button>
            <vxe-radio-button :label="2" content="金股推荐"> </vxe-radio-button>
          </vxe-radio-group>
        </div>

        <v-card class="border-radius-md mt-6 pa-3" v-show="currTab === 0">
          <div>
            <div class="pt-2">
              <vxe-table
                class="mytable-scrollbar"
                :data="portfolioDetail.daily.stocks"
                border
                resizable
                show-overflow
                show-header-overflow
                highlight-hover-row
                min-height="100"
                align="center"
                :column-config="{ resizable: true }"
                size="small"
                :stripe="true"
                :sort-config="{ trigger: 'cell', multiple: true }"
                :scroll-y="{ mode: 'wheel', gt: 100 }"
                :empty-render="{ name: 'NotData' }"
              >
                <vxe-column min-width="70" field="name" title="公司">
                  <template #default="{ row }">
                    {{ row.name }}
                  </template>
                </vxe-column>
                <vxe-column min-width="70" field="code" title="代码">
                  <template #default="{ row }">
                    {{ row.code }}
                  </template>
                </vxe-column>
                <vxe-column min-width="70" field="start_date" title="推荐时间">
                  <template #default="{ row }">
                    {{ row.start_date.split(" ")[0] }}
                  </template>
                </vxe-column>
                <vxe-column min-width="70" field="end_date" title="截至时间">
                  <template #default="{ row }">
                    {{ row.end_date.split(" ")[0] }}
                  </template>
                </vxe-column>
                <vxe-column field="applies" title="区间涨跌幅">
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.applies)">{{
                      $stockHelpers.getPercent(row.applies)
                    }}</span>
                  </template>
                </vxe-column>

                <vxe-column min-width="70" field="sw_code" title="板块">
                  <template #default="{ row }">
                    {{ row.sw_code }}
                  </template>
                </vxe-column>

                <vxe-column field="sw_applies" title="板块收益">
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.sw_applies)">{{
                      $stockHelpers.getPercent(row.sw_applies)
                    }}</span>
                  </template>
                </vxe-column>
                <vxe-column field="excess" title="超额收益">
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.excess)">{{
                      $stockHelpers.getPercent(row.excess)
                    }}</span>
                  </template>
                </vxe-column>
              </vxe-table>
            </div>
          </div>
        </v-card>
        <v-card class="border-radius-md mt-6 pa-3" v-show="currTab === 1">
          <div>
            <div class="pt-2">
              <vxe-table
                class="mytable-scrollbar"
                :data="month"
                border
                min-height="100"
                resizable
                show-overflow
                show-header-overflow
                highlight-hover-row
                align="center"
                :column-config="{ resizable: true }"
                size="small"
                :stripe="true"
                :sort-config="{ trigger: 'cell', multiple: true }"
                :scroll-y="{ mode: 'wheel', gt: 100 }"
                :empty-render="{ name: 'NotData' }"
                :span-method="mergeRowMethod"
              >
                <vxe-column
                  min-width="70"
                  field="reporting_time"
                  title="汇报时间"
                >
                  <template #default="{ row }">
                    {{ row.reporting_time.split(" ")[0] }}
                  </template>
                </vxe-column>
                <vxe-column min-width="70" field="title" title="标题">
                  <template #default="{ row }">
                    {{ row.title }}
                  </template>
                </vxe-column>
                <vxe-colgroup title="推荐标的">
                  <vxe-column field="stock_name" title="名称">
                    <template #default="{ row }">
                      {{ row.stock_name }}
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_code" title="代码">
                    <template #default="{ row }">
                      {{ row.stock_code }}
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_start_date" title="推荐时间">
                    <template #default="{ row }">
                      {{ row.stock_start_date.split(" ")[0] }}
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_end_date" title="截至时间">
                    <template #default="{ row }">
                      {{ row.stock_end_date.split(" ")[0] }}
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_applies" title="涨跌幅">
                    <template #default="{ row }">
                      <span
                        :style="$stockHelpers.getColor(row.stock_applies)"
                        >{{ $stockHelpers.getPercent(row.stock_applies) }}</span
                      >
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_excess" title="超额收益">
                    <template #default="{ row }">
                      <span :style="$stockHelpers.getColor(row.stock_excess)">{{
                        $stockHelpers.getPercent(row.stock_excess)
                      }}</span>
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_sw_code" title="相关板块">
                    <template #default="{ row }">
                      {{ row.stock_sw_code }}
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_sw_applies" title="相关板块涨跌幅">
                    <template #default="{ row }">
                      <span
                        :style="$stockHelpers.getColor(row.stock_sw_applies)"
                        >{{
                          $stockHelpers.getPercent(row.stock_sw_applies)
                        }}</span
                      >
                    </template>
                  </vxe-column>
                </vxe-colgroup>

                <!-- <vxe-column min-width="70" field="sw_code" title="相关板块">
                  <template #default="{ row }">
                    {{ row.sw_code }}
                  </template>
                </vxe-column>
                <vxe-column field="sw_applies" title="相关板块涨跌幅">
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.applies)">{{
                      $stockHelpers.getPercent(row.applies)
                    }}</span>
                  </template>
                </vxe-column> -->
                <vxe-column field="avg_excess" title="平均超额收益">
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.avg_excess)">{{
                      $stockHelpers.getPercent(row.avg_excess)
                    }}</span>
                  </template>
                </vxe-column>
                <vxe-column field="count_excess" title="超额收益">
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.count_excess)">{{
                      $stockHelpers.getPercent(row.count_excess)
                    }}</span>
                  </template>
                </vxe-column>
              </vxe-table>
            </div>
          </div>
        </v-card>
        <v-card class="border-radius-md mt-6 pa-3" v-show="currTab === 2">
          <div>
            <div class="pt-2">
              <vxe-table
                class="mytable-scrollbar"
                :data="king"
                min-height="100"
                border
                resizable
                show-overflow
                show-header-overflow
                highlight-hover-row
                align="center"
                :column-config="{ resizable: true }"
                size="small"
                :stripe="true"
                :sort-config="{ trigger: 'cell', multiple: true }"
                :scroll-y="{ mode: 'wheel', gt: 100 }"
                :empty-render="{ name: 'NotData' }"
                :span-method="mergeRowMethod"
              >
                <vxe-column min-width="70" field="title" title="金股推荐">
                  <template #default="{ row }">
                    {{ row.title }}
                  </template>
                </vxe-column>

                <vxe-colgroup title="推荐金股">
                  <vxe-column field="stock_code" title="代码">
                    <template #default="{ row }">
                      {{ row.stock_code }}
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_name" title="名称">
                    <template #default="{ row }">
                      {{ row.stock_name }}
                    </template>
                  </vxe-column>

                  <vxe-column field="stock_start_date" title="开始时间">
                    <template #default="{ row }">
                      {{ row.stock_start_date.split(" ")[0] }}
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_end_date" title="结束时间">
                    <template #default="{ row }">
                      {{ row.stock_end_date.split(" ")[0] }}
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_applies" title="涨跌幅">
                    <template #default="{ row }">
                      <span
                        :style="$stockHelpers.getColor(row.stock_applies)"
                        >{{ $stockHelpers.getPercent(row.stock_applies) }}</span
                      >
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_excess" title="超额收益">
                    <template #default="{ row }">
                      <span :style="$stockHelpers.getColor(row.stock_excess)">{{
                        $stockHelpers.getPercent(row.stock_excess)
                      }}</span>
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_sw_code" title="相关板块">
                    <template #default="{ row }">
                      {{ row.stock_sw_code }}
                    </template>
                  </vxe-column>
                  <vxe-column field="stock_sw_applies" title="相关板块涨跌幅">
                    <template #default="{ row }">
                      <span
                        :style="$stockHelpers.getColor(row.stock_sw_applies)"
                        >{{
                          $stockHelpers.getPercent(row.stock_sw_applies)
                        }}</span
                      >
                    </template>
                  </vxe-column>
                </vxe-colgroup>

                <!-- <vxe-column min-width="70" field="sw_code" title="相关板块">
                  <template #default="{ row }">
                    {{ row.sw_code }}
                  </template>
                </vxe-column>
                <vxe-column field="sw_applies" title="相关板块涨跌幅">
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.applies)">{{
                      $stockHelpers.getPercent(row.applies)
                    }}</span>
                  </template>
                </vxe-column> -->
                <vxe-column field="avg_excess" title="月度平均">
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.avg_excess)">{{
                      $stockHelpers.getPercent(row.avg_excess)
                    }}</span>
                  </template>
                </vxe-column>
                <vxe-column field="count_applies" title="累计">
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.avg_excess)">{{
                      $stockHelpers.getPercent(row.avg_excess)
                    }}</span>
                  </template>
                </vxe-column>
                <vxe-column field="avg_excess" title="超额收益">
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.count_excess)">{{
                      $stockHelpers.getPercent(row.count_excess)
                    }}</span>
                  </template>
                </vxe-column>
                <vxe-column field="count_excess" title="累计超额收益">
                  <template #default="{ row }">
                    <span :style="$stockHelpers.getColor(row.count_excess)">{{
                      $stockHelpers.getPercent(row.count_excess)
                    }}</span>
                  </template>
                </vxe-column>
              </vxe-table>
            </div>
          </div>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Recommend from "@/api/recommend.js";
import XEUtils from "xe-utils";
import PortfolioEdit from "@/components/Portfolio/PortfolioEdit";
import Trading from "@/components/Portfolio/Trading";
import HoldingPie from "@/components/Portfolio/HoldingPie";
import TradingHistory from "@/components/Portfolio/TradingHistory";
import YieldChart from "@/components/Portfolio/YieldChart";

export default {
  name: "PortfolioDetail",
  components: {
    Trading,
    HoldingPie,
    TradingHistory,
    YieldChart,
  },
  computed: {},
  data() {
    return {
      isSelf: false,

      pageLoading: true,
      portfolioDetail: {},
      benchmarkTypeMap: [],
      benchmarkType: "沪深300",

      haveShowInstructions: false,
      currentTag: "",
      currTab: 0,
      month: [],
      king: [],
      end_date: "",
    };
  },
  mounted() {
    // this.getPortfolioDetail();
    Bus.$on("refreshRecommendDetail", (e, date) => {
      this.currentTag = e.key;
      this.end_date = date;
      if (this.currentTag) {
        this.getPortfolioDetail();
      }
    });
    Bus.$on("refreshPortfolioLists", (date) => {
      this.end_date = date;
      if (this.currentTag) {
        this.getPortfolioDetail();
      }
    });
  },

  methods: {
    instructions() {
      this.$layer.alert(
        `	<div style="font-size:14px">
                          <b>本系统使用实时价格结算，每天晚上结算一次，<span style="color:orange">非实时结算！</span></b><br>
                          <b style="color:orange">注意：滑块调整的位置是个股占总资产的权重，非个股仓位。</b><br>
                          <b>添加成分股：</b><br>
                              (1)在详细仓位的右上角点击“添加成分股”，小窗口弹出。在弹出窗格上方选择股票，填入调仓理由（从<span style="color:red">逻辑</span>、<span style="color:red">业绩</span>、<span style="color:red">催化剂</span>三个方面进行说明，同时进行必要的<span style="color:red">风险提示</span>），拖动滑块调整仓位（最小10%）。<br>
                              (2)在交易日买入，在当日收盘前调入组合，按照下单的<span style="color:orange">下一分钟均价</span>计算持股成本。<br>
                              (3)在当日收盘后或非交易日调入组合，按照下个交易日九点三十分的<span style="color:orange">一分钟均价</span>成交。<br>
                          <b>调仓、清仓：</b><br>
                              (1)盘中可调仓换股，每天结算以收盘前的最后一次调仓为准。<br>
                              (2)每日7点至8点半为结算时间，结算调仓期间不可调仓。<br>
                          <b>管理员、联系人：</b><br>
                              李跃/付宇娣18017835789<br>
                      </div>
                  `,
        {
          title: "模拟组合使用说明",
          area: ["600px", "450px"],
        },
        (layerid) => {
          this.$layer.close(layerid);
        }
      );
    },
    getPortfolioDetail() {
      this.pageLoading = true;
      Recommend.recommendsDetail(this.currentTag, { end_date: this.end_date })
        .then((res) => {
          //console.log(res.data);
          const temp = [];
          const temp2 = [];
          res.data.month.forEach((item) => {
            item.stocks.forEach((stock) => {
              temp.push({
                title: item.title,
                reporting_time: item.reporting_time,
                count_excess: item.count_excess,
                count_applies: item.count_applies,
                avg_excess: item.avg_excess,
                avg_applies: item.avg_applies,
                stock_name: stock.name,
                stock_code: stock.code,
                stock_applies: stock.applies,
                stock_end_date: stock.end_date,
                stock_sw_applies: stock.sw_applies,
                stock_sw_code: stock.sw_code,
                stock_excess: stock.excess,
                stock_start_date: stock.start_date,
              });
            });
          });
          res.data.king.forEach((item) => {
            item.stocks.forEach((stock) => {
              temp2.push({
                title: item.title,
                reporting_time: item.reporting_time,
                count_excess: item.count_excess,
                count_applies: item.count_applies,
                avg_excess: item.avg_excess,
                avg_applies: item.avg_applies,
                stock_name: stock.name,
                stock_code: stock.code,
                stock_applies: stock.applies,
                stock_end_date: stock.end_date,
                stock_sw_applies: stock.sw_applies,
                stock_sw_code: stock.sw_code,
                stock_excess: stock.excess,
                stock_start_date: stock.start_date,
              });
            });
          });
          this.portfolioDetail = res.data;
          this.month = temp;
          this.king = temp2;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    portfolioEdit() {
      this.$layer.iframe({
        content: {
          content: PortfolioEdit,
          parent: this,
          data: { iframeData: { ...this.portfolioDetail } },
        },
        area: ["600px", "400px"],
        title: "修改组合(带*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    mergeRowMethod({ row, _rowIndex, column, visibleData }) {
      const fields = ["reporting_time", "title", "count_excess", "avg_excess"];
      const cellValue = row[column.field];
      if (cellValue && fields.includes(column.field)) {
        const prevRow = visibleData[_rowIndex - 1];
        let nextRow = visibleData[_rowIndex + 1];
        if (prevRow && prevRow[column.field] === cellValue) {
          return { rowspan: 0, colspan: 0 };
        } else {
          let countRowspan = 1;
          while (nextRow && nextRow[column.field] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex];
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 };
          }
        }
      }
    },
  },
};
</script>
