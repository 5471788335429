import Request from "@/components/request/js/index.js";

export default {
  //获取推荐列表
  recommends(date) {
    return Request().get("/api/recommends", {
      header: {
        contentType: "application/json",
      },
      data: date,
    });
  },
  recommendsDetail(id, date) {
    return Request().get(`/api/recommends/${id}`, {
      header: {
        contentType: "application/json",
      },
      data: date,
    });
  },
};
