<template>
  <v-container fluid class="px-6 py-1">
    <v-row class="mt-1">
      <div style="width: 380px" class="d-flex position-fixed pa-1">
        <v-card
          class="border-radius-xl mt-2 pt-0 pb-4"
          v-if="this.$commonHelpers.getPermissions('portfolio.insiders')"
        >
          <PortfolioList
            :tableHight="tableHeight"
            :isOut="false"
          ></PortfolioList>
        </v-card>
      </div>
      <div
        class="d-flex flex-column py-3 px-2"
        style="width: calc(100vw - 500px); margin-left: 390px"
      >
        <div class="d-flex justify-space-between">
          <div class="d-flex">
            <div class="pt-1">
              <h2
                class="text-h6 text-typo font-weight-bolder"
                v-if="currentTag"
              >
                {{ currentTag }}详情
              </h2>
            </div>
          </div>
        </div>
        <RecommendDetail></RecommendDetail>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import PortfolioList from "@/components/Recommend/PortfolioList";
import RecommendDetail from "@/components/Recommend/RecommendDetail";
import Bus from "@/components/Bus/bus.js";
export default {
  name: "Recommend",
  components: {
    PortfolioList,
    RecommendDetail,
  },
  computed: {},
  data() {
    return {
      tableHeight: "500",
      isAdmin:
        this.$commonHelpers.getPermissions("portfolio.external") &&
        this.$commonHelpers.getPermissions("portfolio.insiders"),
      currentTag: "",
    };
  },
  mounted() {
    this.tableHeight = document.body.offsetHeight - 160;
    // if (this.isAdmin) {
    //   this.tableHeight = (document.body.offsetHeight - 500) / 2;
    // } else {
    //   this.tableHeight = document.body.offsetHeight - 160;
    // }
    Bus.$on("refreshRecommendDetail", (e) => {
      this.currentTag = e.name;
    });
  },
  methods: {
    getTagClass(tag) {
      if (tag.level) {
        if (tag.level === 1) {
          return "red lighten-5";
        } else if (tag.level === 2) {
          return "orange lighten-5";
        } else if (tag.level === 3) {
          return "green lighten-5";
        }
      } else {
        if (tag.id != "race" && tag.id != "style") {
          return "blue-grey lighten-5";
        } else {
          return "";
        }
      }
    },
  },
};
</script>
