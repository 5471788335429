<template>
  <div>
    <vxe-toolbar class="mx-3">
      <template v-slot:buttons>
        <!-- <span
          class="mr-2"
          v-if="
            $commonHelpers.getPermissions('portfolio.external') &&
            $commonHelpers.getPermissions('portfolio.insiders')
          "
          >{{ isOut ? "服务机构" : "研究员" }}</span
        >

        <vxe-input
          v-model="filterName"
          type="search"
          placeholder="搜索"
          class="mx-1"
        ></vxe-input> -->
        <!-- <vxe-select
          v-model="benchmarkType"
          size="medium"
          placeholder="业绩基准"
          class="mx-1"
          style="width: 130px"
          status="my-primary"
        >
          <vxe-option
            v-for="(item, index) in benchmarkTypeMap"
            :key="index"
            :value="item"
            :label="'基准:' + item"
          >
          </vxe-option>
        </vxe-select> -->
        <!-- <vxe-button
          v-if="!$commonHelpers.getPermissions(['组合观察员'])"
          @click="portfolioAdd()"
          size="medium"
          content="新建组合"
          class="mx-1"
          status="my-primary"
        ></vxe-button> -->
        <!-- <vxe-button
          @click="exportTable()"
          size="medium"
          content="导出"
          class="ml-0 mr-1"
          status="my-primary"
        >
        </vxe-button> -->
        <vxe-input
          v-model="end_date"
          placeholder="选择日期"
          type="date"
          :disabled-method="disabledDateMethod"
          transfer
          clearable
        ></vxe-input>
        <vxe-button
          @click="getPortfolioList()"
          size="medium"
          content="搜索"
          class="ml-8"
          status="my-primary"
        >
        </vxe-button>
      </template>
    </vxe-toolbar>

    <div :style="{ height: tableHight + 'px' }">
      <vxe-table
        id="portfolioTable"
        class="mytable-scrollbar"
        :data="list"
        :border="false"
        resizable
        show-overflow
        show-header-overflow
        align="center"
        ref="portfolioTable"
        :loading="portfolioTableLoading"
        :column-config="{ resizable: true }"
        height="100%"
        size="mini"
        :stripe="true"
        :sort-config="{ trigger: 'cell', multiple: true }"
        :scroll-y="{ mode: 'wheel', gt: 100 }"
        :empty-render="{ name: 'NotData' }"
        :export-config="{}"
        :span-method="mergeRowMethod"
      >
        <vxe-column width="90" field="key" title="姓名">
          <template #default="{ row }">
            <div class="d-flex justify-center">
              <a
                href="javascript:;"
                @click="openPortfolio(row)"
                class="text-xs font-weight-normal text-typo text-align-center"
              >
                {{ row.name }}
              </a>
            </div>
          </template>
        </vxe-column>

        <vxe-column width="70" field="link" title="链接">
          <template #default="{ row }">
            <div class="d-flex justify-center">
              <div
                style="
                  word-break: keep-all;
                  white-space: pre-line;
                  text-align: center;
                "
              >
                {{ row.link }}
              </div>
            </div>
          </template>
        </vxe-column>

        <vxe-column width="90" field="return" title="收益率">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-center">
              <span :style="$stockHelpers.getColor(row.returns)">{{
                row.returns | formatNum
              }}</span>
            </div>
          </template>
        </vxe-column>

        <vxe-column width="90" field="excess" title="超额收益">
          <template #default="{ row }">
            <div class="d-flex justify-center">
              <span :style="$stockHelpers.getColor(row.excess)">{{
                row.excess | formatNum
              }}</span>
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Recommend from "@/api/recommend.js";
import PortfolioAdd from "@/components/Portfolio/PortfolioAdd";
import XEUtils from "xe-utils";
export default {
  name: "RecommendList",
  components: {},
  props: {
    tableHight: "",
    isOut: false,
  },
  computed: {
    list() {
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, "gi");
        const searchProps = ["name", "user_name"];
        const rest = this.tableData.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        return rest;
      }
      return this.tableData;
    },
  },
  data() {
    return {
      portfolioTableLoading: false,
      filterName: "",
      tableData: [],
      benchmarkTypeMap: [],
      benchmarkType: "沪深300",
      end_date: "",
    };
  },
  filters: {
    //格式化小数加%
    formatNum: function (value) {
      if (value == 0) {
        return "0.00%";
      }
      if (value == undefined) {
        return "0.00%";
      }
      return value.toFixed(2) + "%";
    },
  },

  watch: {
    benchmarkType: {},
  },
  mounted() {
    this.getPortfolioList();
    Bus.$on("refreshPortfolioList", (e) => {
      this.getPortfolioList();
    });
  },
  methods: {
    getPortfolioList() {
      this.portfolioTableLoading = true;
      Bus.$emit("refreshPortfolioLists", this.end_date);
      Recommend.recommends({ end_date: this.end_date })
        .then((res) => {
          //console.log(res.data);
          const temp = [];
          res.data.forEach((item) => {
            console.log(item);
            for (var item2 in item.data) {
              console.log(item2);
              let link =
                item2 == "month"
                  ? "月度推荐"
                  : item2 == "daily"
                  ? "日常推荐"
                  : "金股推荐";
              temp.push({
                name: item.user_name,
                link: link,
                returns: item.data[item2].count_applies,
                excess: item.data[item2].count_excess,
                key: encodeURIComponent(item.user_id),
              });
            }
          });
          console.log(temp);
          this.tableData = temp;
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.portfolioTableLoading = false;
        });
    },
    openPortfolio(item) {
      Bus.$emit("refreshRecommendDetail", item, this.end_date);
    },
    portfolioAdd() {
      this.$layer.iframe({
        content: {
          content: PortfolioAdd,
          parent: this,
          data: { iframeData: {} },
        },
        area: ["600px", "200px"],
        title: "新建组合(带*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    exportTable() {
      this.$refs.portfolioTable.openExport({});
    },
    // 通用行合并函数（将相同多列数据合并为一行）
    mergeRowMethod({ row, _rowIndex, column, visibleData }) {
      const fields = ["key"];
      const cellValue = row[column.field];
      if (cellValue && fields.includes(column.field)) {
        const prevRow = visibleData[_rowIndex - 1];
        let nextRow = visibleData[_rowIndex + 1];
        if (prevRow && prevRow[column.field] === cellValue) {
          return { rowspan: 0, colspan: 0 };
        } else {
          let countRowspan = 1;
          while (nextRow && nextRow[column.field] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex];
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 };
          }
        }
      }
    },
    disabledDateMethod(params) {
      const { date } = params;
      const today = new Date();
      const lastYear = new Date().getFullYear() - 1;
      const startDate = new Date(lastYear, 0, 1); // 去年1月1日
      const endDate = new Date(); // 当前日期

      // 禁用不在去年1月1日到今天的日期
      return date < startDate || date > endDate;
    },
  },
};
</script>
